const baseStrings = {
	translation: {
		home: "Startseite",
		menu: "Speisekarte",
		opportunity: "speziell",
		opportunityProducts: "Spezialangebote",
		payment: "Zahlung",
		back: "hinten",
		yourName: "dein Name",
		roomNumber: "Zimmernummer ",
		deliveryInfo: "Gastinformationen ",
		yourDeliveryInfo: "Gastinformationen",
		paymentInfo: " Zahlungsinformationen ",
		productTotal: " Gesamtsumme der Produkte ",
		serviceFee: " Servicegebühr ",
		total: " Gesamtbetrag ",
		online: "Online",
		card: " Kreditkarte bei Lieferung",
		myCart: " meinWarenkorb",
		cash: " Bargeld",
		note: "Not",
		delivery: " Lieferung ",
		payment: " Zahlung",
		orderNote: " Geben Sie Ihre Bestellnotiz ein ",
		completeOrder: " Bestellung abschließen ",
		enjoyYourMeal: " Guten Appetit ",
		yourOrderReceived: " Ihre Bestellung wurde erhalten ",
		// completeOrder: " Ihre Bestellung wurde abgeschlossen. ",
		hello: " Hallo ",
		uuiError: " Bitte scannen Sie den QR-Code, um das Menü anzuzeigen",
		successful: " Erfolgreich ",
		productAddToCart: " Produkt wurde dem Warenkorb hinzugefügt ",
		addToBasket: " In den Warenkorb legen ",
		removeFromBasket: " Aus dem Warenkorb entfernen ",
		productTitle: " PERSÖNLİCHE PFLEGE",
		itemNotFound: " Artikel nicht gefunden ",
		completeTheOrder: " Bitte schließen Sie die Bestellung ab ",
		selectThePayment: "Bitte wählen Sie Ihre Zahlungsmethode",
		paymentType: " Zahlungsmethode ",
		orderLimitAuthor: " Bestellgrenze {{autor}} {{Währung}}",
		orderLimit: " Bestellgrenze",
		dataNotFound: " Daten nicht gefunden",
		confirmOrder: " Zum Abschließen der Bestellung tippen Sie bitte",
		exchangeInfo: " Wenn Sie in ausländischer Währung bezahlen, wird Ihnen der Wechsel in Türkische Lira gegeben.",
		deliveryInfo:
			" Bitte stellen Sie sicher, dass Sie Ihren Namen und Ihre Zimmernummer korrekt und vollständig angeben, damit Ihre Bestellung ordnungsgemäß zugestellt werden kann.",
		weather: " Wetterbedingungen",
		totalEuro: " Gesamtbetrag (Euro)",
		totalDollar: " Gesamtbetrag (Dollar)",
		totalTL: " Gesamtbetrag (TL)",
		diss: " Sonderangebot für alle Produkte heute!",
		continue: " Weiter ",
		accept: " Ich akzeptiere die Fernabsatzvertrag ",
		done: " Ihre Bestellung wird vorbereitet...",
		enjoyMeal: " Guten Appetit ",
		dk: "MIN (Minute)",
		deliveryAndPayment: " Lieferung und Zahlung ",
		cashCurrency: "Währung wählen",
		continue: "weitermachen",
		other: "Andere Kategorien",

		// preparing: "Ihre Bestellung wird vorbereitet. Guten Appetit...",
		preparing: "Danke! Wir haben Ihre Bestellung erhalten. Bitte vergessen Sie nicht, Ihr Postfach für die Details zu überprüfen.",

		notValid: "Geben Sie Name und Zimmernummer ein",

		searchHome: "Suchen ...",
		notfound: "Daten nicht gefunden",

		searchAuthor: "Suche nach {{author}}",
		useDiscountCode: "Verwenden Sie Ihren Rabattcode",
		discount: "Rabatt",
		discountAuthor: "Rabatt ({{author}})",
		products: "Produkte",
		append: "Anhängen",
		code: "Code",
		checkAuthor: "überprüfe {{author}}",
		"discount-note": "Sie können Ihren Rabattcode in diesem Abschnitt überprüfen und bei Bedarf verwenden",
		"discount-code-details":
			"Der von Ihnen angegebene Code ist {{code}}. Der Name dieses Rabattcodes ist {{name}} und er hat einen Wert von {{amount}} % Rabatt",
		"back-to-menu": "Zurück zum Menü",
		bankErrorTitle: "Zahlung fehlgeschlagen",
		bankErrorDescription: "Bei der Zahlung ist ein Problem aufgetreten. Bitte überprüfen Sie Ihre Karteninformationen und versuchen Sie es erneut",
		minimumCart: "minimum : {{author}}",
		orderNow: "Jetzt bestellen",
		AppointmentDaySelection: "Auswahl des Termintages",
		paymentText: "Barzahlung ist für Bestellungen nicht möglich",
		baklavaSpecialOrderPopup: "Baklava-Geschenk für Ihre Sonderbestellungen",
		baklavaTitle: "Probieren Sie die Tradition",
		baklavaDescFirst: "Erleben Sie die Freude an",
		baklavaDescSecond: "KOSTENLOSES BAKLAVA",
		baklavaDescThird: "bei jeder Bestellung, die Sie heute aufgeben!",
		apply: "bewerben",
		networkDryTitle: "Vorsichtsmaßnahmen vor der chemischen Reinigung",
		networkDrySubtitle:
			"Diese Informationen erklären deutlich, worauf unsere Gäste achten sollten, bevor sie ihre Kleidungsstücke zur chemischen Reinigung abgeben. Bitte lesen Sie sorgfältig.",
		warning: "Warnung",
		networkDryWarning:
			"Produkte, die Sie tagsüber liefern, werden Ihnen <underline>bis 17:30 am nächsten Tag</underline> geliefert. Produkte <underline>werden nicht am selben Tag geliefert.</underline>",

		networkDryDesc1:
			"Etikettenkontrolle: Überprüfen Sie die Pflegeetiketten Ihrer Kleidungsstücke. Stellen Sie sicher, dass sie für die chemische Reinigung geeignet sind und befolgen Sie die Reinigungshinweise. Wenn Sie Kleidungsstücke abgeben, die nicht für die chemische Reinigung geeignet sind, liegt das Risiko für eventuelle Probleme beim Verbraucher.",
		networkDryDesc2:
			"Wertsachen entfernen: Lassen Sie keine Wertsachen (wie Uhren, Halsketten, Bargeld usw.) in den Taschen oder versteckten Fächern Ihrer Kleidungsstücke.",
		networkDryDesc3:
			"Lieferzeit: Ihre Kleidungsstücke werden Ihnen am nächsten Tag bis 17:30 Uhr geliefert. Wenn Sie dringend etwas benötigen, geben Sie dies bitte im Voraus an. (Im Bestellnotizfeld angeben.)",
		networkDryDesc4: "Lieferbedingungen: Geben Sie Ihre Kleidungsstücke nicht mit persönlichen Bügeln und Kleidersäcken ab.",
		networkDryDesc5:
			"Fleckenidentifizierung: Wenn Ihre Kleidungsstücke Flecken aufweisen, identifizieren Sie diese und informieren Sie die Reinigung darüber. Die Kenntnis des Fleckens kann den Reinigungsprozess effektiver machen. (Im Bestellnotizfeld angeben.)",
		networkDryDesc6:
			"Besondere Anweisungen: Wenn ein Teil Ihrer Kleidungsstücke besonders empfindlich ist oder Sie möchten, dass ein bestimmtes Verfahren nicht angewendet wird, geben Sie dies bitte deutlich an. (Im Bestellnotizfeld angeben.)",
		networkDryDesc7:
			"Überprüfung auf Abnutzung und Schäden: Überprüfen Sie Ihre Kleidungsstücke vor der Abgabe auf Abnutzung, Risse oder Schäden. Informieren Sie die Reinigung darüber, um zusätzliche Vorsichtsmaßnahmen zu gewährleisten. (Im Bestellnotizfeld angeben.)",
		networkDryDesc8:
			"Entfernen von Accessoires: Wenn Ihre Kleidungsstücke abnehmbare Accessoires (wie Gürtel, Broschen usw.) haben, entfernen Sie diese vor der Abgabe.",
		networkDryDesc9:
			"Farbverblassung: Überprüfen Sie, ob Ihre Kleidungsstücke anfällig für Farbverblassung sind, und informieren Sie gegebenenfalls die Reinigung. (Im Bestellnotizfeld angeben.)",
		networkDryCheckedLabel: "Ich habe die oben genannten Anweisungen gelesen, verstanden und stimme ihnen zu.",
		tourTitle: "Kostenlos",
		tourDesc: "BOSPORUS BOOTSFAHRT für jede Bestellung über 60 €!",
		tourButton: "Details",
		detailsPage: {
			title:
				"Mit dieser Kampagne gewinnen Sie eine Bosporus-Bootstour, sobald Sie den Mindestbestellwert erreicht haben. Bestellen Sie jetzt, um Ihr kostenloses Bosporus-Tour-Geschenk zu erhalten. Die Kampagnendetails finden Sie unten.",
			desc1: "Pro Bestellung im Wert von 60 € wird nur ein kostenloses Ticket vergeben.",
			desc2: "Um das Geschenk zu gewinnen, muss der Gesamtbetrag Ihres Warenkorbs mindestens 60 € betragen.",
			desc3: "Die Bosporus-Tour beinhaltet ein festes Menü ohne Alkohol und verschiedene Tanzvorführungen, die die türkische Kultur widerspiegeln.",
			desc4: "Das Menü umfasst ein Hauptgericht, Vorspeisen, Dessert und alkoholfreie Getränke.",
			desc5: "Das kostenlose Bosporus-Tour-Ticket kann frühestens am Tag nach der Bestellung genutzt werden.",
			desc6: "Die Reservierungsbestätigung und Tourdetails werden per E-Mail versendet.",
			desc7:
				"Um das kostenlose Bosporus-Tour-Ticket zu erhalten, sind der Name, der Nachname, der Hotelname, die Zimmernummer und gültige Kontaktdaten des Gastes erforderlich.",
			desc8: "Um ein zweites Ticket zu gewinnen, müssen Sie eine weitere Bestellung im Wert von 60 € aufgeben.",
		},

		tourPercentageLabel: "Bestellung abschließen für eine kostenlose BOSPORUS-TOUR",
		completedTourBarText: "Tourdetails werden an Ihre E-Mail gesendet",
		completedSpecialOfferBarText: "Nur noch {{remain}} bis du {{name}} für {{price}} gewinnst!",
		specialOfferPercentageLabel: "Du hast {{name}} für {{price}} gewonnen!",
		cartItem: "Warenkorbelement",
		cartItems: "Warenkorbelemente",
		offers: "Angebote",
		offer: "Angebot",
		orderNumberDoneAuthor: "Bestellnummer: {{author}}",
	},
};

export const de = baseStrings;
