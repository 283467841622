import { useEffect } from "react";

const SupsisEmbedCode = () => {
	useEffect(() => {
		const isCartPage = window.location.pathname.includes("cart");
		const openButton = document.getElementById("ls-openButton");
		if (isCartPage) {
			if (openButton) openButton.style.display = "none";
		} else if (openButton) openButton.style.display = "flex";
	}, [window.location.pathname]);
	return null;
};

export default SupsisEmbedCode;
