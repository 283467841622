const baseStrings = {
	translation: {
		home: "Inicio",
		menu: "Menú",
		opportunity: "Especial",
		opportunityProducts: "Ofertas Especiales",
		payment: "Pago",
		back: "Atrás",
		yourName: "Su Nombre",
		roomNumber: "Número de Habitación",
		deliveryInfo: "Información de Entrega",
		yourDeliveryInfo: "Información de Entrega",
		paymentInfo: "Información de Pago",
		productTotal: "Producto Total",
		serviceFee: "Tarifa de Servicio",
		total: "Total",
		online: "Online",
		card: "Tarjeta de crédito en el momento de la entrega",
		myCart: "Mi Cesta",
		cash: "Efectivo",
		note: "Nota",
		delivery: "Entrega",
		payment: "Pago",
		orderNote: "Ingreser su nota de pedido",
		completeOrder: "Tramitar Pedido",
		enjoyYourMeal: "¡Buen provecho!",
		yourOrderReceived: "Su Pedido Recibido",
		// completeOrder: "Su Pedido Completo",
		hello: "¡Hola!",
		uuiError: "Escanear el ódigo QR para ver el menú",
		successful: "Exitoso",
		productAddToCart: "¡Producto agregado a la cesta!",
		addToBasket: "Añadir a la cesta",
		removeFromBasket: "Vaciar cesta",
		productTitle: "CUIDADO PERSONAL",
		itemNotFound: "Producto no encontrado",
		completeTheOrder: "Completar el pedido",
		selectThePayment: "Elegir el tipo de pago",
		paymentType: "Tipo de Pago",
		orderLimitAuthor: "Limite de pedido {{author}} {{currency}}",
		orderLimit: "Límite de pedido",
		dataNotFound: "Datos no encontrados",
		confirmOrder: "Tocar para completar el pedido",
		exchangeInfo: "Si pagas en moneda extranjera, te darán el cambio en liras turcas.",
		deliveryInfo:
			"Para que su pedido se entregue correctamente, asegúrese de escribir completamente su nombre y la información del número de habitación.",
		weather: "Clima",
		totalEuro: "Total (Euro)",
		totalDollar: "Total (Dólar)",
		totalTL: "Total (Lira Turca)",
		diss: "¡Descuento en todos los productos para hoy!",
		continue: "Continuar",
		accept: "Acepto el contrato de venta a distancia",
		done: "Su pedido se está preparando...",
		enjoyMeal: "Buen Provecho",
		dk: "Min (Minuto)",
		deliveryAndPayment: "Entrega y Pago",

		continue: "Continuar",
		cashCurrency: "Seleccione el tipo de moneda",
		other: "Otras categorías",

		// preparing: "Se está preparando su pedido Disfrute de su comida...",
		preparing: "¡Gracias! Hemos recibido tu pedido. Por favor, no olvides revisar tu bandeja de entrada para los detalles.",

		notValid: "Ingrese el nombre y el número de la habitación",

		searchHome: "Buscar ...",
		notfound: "Datos no encontrados",

		searchAuthor: "Buscar {{autor}}",
		useDiscountCode: "Usa tu código de descuento",
		discount: "Descuento",
		discountAuthor: "Descuento ({{author}})",
		products: "Productos",
		append: "Agregar",
		code: "Código",
		checkAuthor: "verificar {{author}}",
		"discount-note": "Puedes consultar tu código de descuento desde esta sección y utilizarlo si es necesari",
		"discount-code-details":
			"El código que proporcionaste es {{code}}. El nombre de este código de descuento es {{name}} y tiene un valor de {{amount}}% de descuento",
		"back-to-menu": "Volver al menú",
		bankErrorTitle: "Pago fallido",
		bankErrorDescription: "Hay un problema con el pago. Verifique la información de su tarjeta e inténtelo nuevamente",
		minimumCart: "mínimo : {{author}}",
		orderNow: "Ordene ahora",
		AppointmentDaySelection: "Selección del día de la cita",
		paymentText: "No se acepta pago en efectivo para pedidos",
		baklavaSpecialOrderPopup: "Regalo Baklava para tus pedidos especiales.",
		baklavaTitle: "prueba la tradición",
		baklavaDescFirst: "Experimenta la alegría de",
		baklavaDescSecond: "BAKLAVA GRATIS",
		baklavaDescThird: "¡con cada pedido que hagas hoy!",
		apply: "aplicar",
		networkDryTitle: "Precauciones a tomar antes de la limpieza en seco",
		networkDrySubtitle:
			"Esta información detalla claramente en qué deben prestar atención nuestros huéspedes antes de entregar sus artículos para la limpieza en seco. Por favor, lea detenidamente.",
		warning: "Advertencia",
		networkDryWarning:
			"Los productos que entregue durante el día se le entregarán <underline>antes de las 17:30 del día siguiente</underline>. Los productos <underline>no se entregarán el mismo día.</underline>",

		networkDryDesc1:
			"Inspección de etiquetas: Examine las etiquetas de cuidado de sus prendas. Asegúrese de que sean aptas para la limpieza en seco y siga las instrucciones de limpieza. Si entrega prendas que no son adecuadas para la limpieza en seco, cualquier problema potencial será responsabilidad del consumidor.",
		networkDryDesc2:
			"Notificación de objetos de valor: No deje objetos de valor (como relojes, collares, dinero en efectivo, etc.) en los bolsillos o compartimentos ocultos de sus prendas.",
		networkDryDesc3:
			"Tiempo de entrega: Sus prendas le serán entregadas a las 5:30 PM del día siguiente después de ser recibidas. Si tiene necesidades urgentes, por favor indíquelo con antelación. (Especifique en la sección de notas del pedido.)",
		networkDryDesc4: "Condiciones de entrega: No entregue sus prendas con perchas y bolsas personales.",
		networkDryDesc5:
			"Detección de manchas: Si hay manchas en sus prendas, identifíquelas e informe al encargado de la limpieza en seco. Saber qué es la mancha puede ayudar a hacer el proceso de limpieza más efectivo. (Especifique en la sección de notas del pedido.)",
		networkDryDesc6:
			"Instrucciones especiales: Si alguna parte de sus prendas es particularmente sensible o si no desea que se aplique un proceso determinado, por favor indíquelo claramente. (Especifique en la sección de notas del pedido.)",
		networkDryDesc7:
			"Inspección de desgaste y daños: Antes de entregar sus prendas, revise si hay algún desgaste, desgarro o daño. Informe al encargado de la limpieza en seco para que se tomen precauciones adicionales. (Especifique en la sección de notas del pedido.)",
		networkDryDesc8:
			"Retire los accesorios: Si sus prendas tienen accesorios removibles (como cinturones, broches, etc.), retírelos antes de entregar sus prendas.",
		networkDryDesc9:
			"Desvanecimiento del color: Verifique si sus prendas son propensas a la decoloración y, de ser así, informe al encargado de la limpieza en seco. (Especifique en la sección de notas del pedido.)",
		networkDryCheckedLabel: "He leído, entendido y acepto las instrucciones mencionadas anteriormente.",
		tourTitle: "Gratis",
		tourDesc: "¡TOUR EN BARCO POR EL BÓSFORO por cada pedido de 60 €!",
		tourButton: "Detalles",
		detailsPage: {
			title:
				"Con esta campaña, tan pronto como alcances el monto mínimo de pedido, ganarás un tour por el Bósforo en barco. Haz tu pedido ahora para recibir tu regalo: un tour gratuito por el Bósforo. Los detalles de la campaña se proporcionan a continuación.",
			desc1: "Solo se otorga un boleto gratuito por cada pedido de 60 €.",
			desc2: "Para ganar el regalo, el total de tu carrito debe ser de al menos 60 €.",
			desc3: "El tour por el Bósforo incluye un menú fijo sin alcohol y varias presentaciones de danza que reflejan la cultura turca.",
			desc4: "El menú incluye un plato principal, aperitivos, postre y bebidas no alcohólicas.",
			desc5: "El boleto gratuito para el tour por el Bósforo se puede utilizar como muy pronto el día después de realizar el pedido.",
			desc6: "La confirmación de la reserva y los detalles del tour se enviarán por correo electrónico.",
			desc7:
				"Para recibir el boleto gratuito para el tour por el Bósforo, se requiere el nombre, apellido, nombre del hotel, número de habitación e información de contacto válida del huésped.",
			desc8: "Para ganar un segundo boleto, debes hacer otro pedido de 60 €.",
		},

		tourPercentageLabel: "Completa el pedido hasta {{amount}} para un TOUR GRATIS POR EL BÓSFORO",
		completedTourBarText: "Los detalles del tour se enviarán a tu correo electrónico",
		completedSpecialOfferBarText: "¡Quedan {{remain}} para ganar {{name}} por {{price}}!",
		specialOfferPercentageLabel: "¡Has ganado {{name}} por {{price}}!",
		cartItem: "Artículo en el carrito",
		cartItems: "Artículos en el carrito",
		offers: "ofertas",
		offer: "oferta",
		orderNumberDoneAuthor: "Número de pedido: {{author}}",
	},
};

export const es = baseStrings;
