const baseStrings = {
	translation: {
		errors: {
			"is-require": "{{author}} zorunludur",
			"is-not-valid": "{{author}} geçerli değil",
			"please-enter-your": "lütfen {{author}} girin",
			"please-enter": "lütfen {{author}} girin",
			"please-select-your": "lütfen {{author}} girin",
			"must-be": "{{author}} {{kind}} olmalıdır",
			min: "The lowest character is {{author}}",
			max: "The maximum number of characters is {{author}}",
		},
		apply: "Uygula",

		home: "Ana sayfa",
		menu: "Menü",
		opportunity: "Özel",
		opportunityProducts: "Özel Teklifler",
		payment: "Ödeme",
		back: "Back",
		yourName: "Ad Soyad",
		name: "Ad Soyad",
		roomNumber: "Oda Numarası",
		deliveryInfo: "Misafir Bilgileri",
		yourDeliveryInfo: "Misafir Bilgileri",
		paymentInfo: "Ödeme Bilgileri",
		productTotal: "Ürün Toplamı",
		serviceFee: "Servis Ücreti",
		total: "Toplam Tutar",
		online: "Online",
		card: "Teslimatta Kredi Kartı",
		myCart: "Sepetim",
		cash: "Nakit",
		note: "Özel talimat ve notlarınızı buraya yazabilirsiniz.",
		delivery: "Teslimat",
		payment: "Ödeme",
		orderNote: "Sipariş notunuzu girin",
		completeOrder: "Siparişi Tamamla",
		enjoyYourMeal: "Afiyet olsun!",
		yourOrderReceived: "Siparişiniz Alındı",
		// completeOrder: "Siparişiniz Tamamlandı",
		hello: "Merhaba",
		uuiError: "Sadece QR kodu tarayarak girebilirsiniz.",
		successful: "Başarılı",
		productAddToCart: "Ürün Sepete Eklendi!",
		addToBasket: "Sepete ekle",
		removeFromBasket: "Sepetten çıkar",
		productTitle: "KİŞİSEL BAKIM",
		itemNotFound: "Öğe Bulunamadı",
		completeTheOrder: "Lütfen Siparişi Tamamlayın",
		selectThePayment: "Lütfen Ödeme Türünü Seçin",
		paymentType: "Ödeme şekli",
		orderLimitAuthor: "Sipariş Sınırı {{author}} {{currency}}",
		orderLimit: "Sipariş Limiti",
		dataNotFound: "Veri bulunamadı",
		confirmOrder: "Siparişi Tamamlamak İçin Dokunun",
		exchangeInfo: "Eğer yabancı para birimiyle öderseniz, Para üstü Türk Lirası olarak verilir.",
		deliveryInfo: "Siparişinizin doğru şekilde teslim edilebilmesi için lütfen isim ve oda numarası bilgilerinizi eksiksiz yazdığınızdan emin olunuz.",
		weather: "Hava Durumu",
		totalEuro: "Toplam Tutar (Euro)",
		totalDollar: "Toplam Tutar (Dolar)",
		totalTL: "Toplam Tutar (TL)",
		diss: "Bugüne Özel Tüm Ürünlerde İndirim!",
		continue: "Devam",
		cashCurrency: "Para Birimini Seçiniz",
		other: "Diğer Kategoriler",
		// preparing: "Siparişiniz Hazırlanıyor Afiyet Olsun...",
		preparing: "Teşekkürler! Siparişinizi aldık, detaylar için e-posta kutunuzu kontrol etmeyi unutmayın.",
		notValid: "Ad Soyad ve Oda Numarası Giriniz",
		searchHome: "Ara ...",
		notfound: "Veri Bulunamadı",

		searchAuthor: "{{author}}'da ara",
		useDiscountCode: "İndirim kodunuzu kullanın",
		discount: "İndirim",
		discountAuthor: "İndirim ({{author}})",
		products: "Ürünler",
		append: "Ekle",
		code: "Kod",
		checkAuthor: "{{author}}'u kontrol et",
		"discount-note": "İndirim kodunuz varsa lütfen giriniz",
		"discount-code-details": "Verdiğiniz kod {{code}}. Bu indirim kodunun adı {{name}} olup, %{{amount}} indirim değerine sahiptir",
		"back-to-menu": "Menüye dön",
		bankErrorTitle: "Ödeme başarısız oldu",
		bankErrorDescription: "Ödemede sorun oluştu. Lütfen kart bilgilerinizi kontrol edip tekrar deneyin",
		minimumCart: "minimum : {{author}}",
		orderNow: "Şimdi sipariş verin",
		AppointmentDaySelection: "Randevu Günü Seçimi",
		paymentText: "Siparişlerde Nakit Ödeme Kabul Edilmemektedir",
		baklavaSpecialOrderPopup: "Size özel siparişlerinizde baklava hediye",
		baklavaTitle: "geleneği tadın",
		baklavaDescFirst: "Mutluluğu yaşayın",
		baklavaDescSecond: "BEDAVA BAKLAVA",
		baklavaDescThird: "bugün verdiğiniz her siparişte!",
		apply: "başvurmak",
		networkDryTitle: "Kuru Temizleme Öncesi Dikkat Edilmesi Gerekenler",
		networkDrySubtitle:
			"Bu bilgilendirme, misafirlerimizin kuru temizleme hizmetine ürün teslim etmeden önce nelere dikkat etmeleri gerektiğini açıkça ifade eder. Lütfen dikkatli okuyun.",
		warning: "Uyarı",
		networkDryWarning:
			"Uyarı: Gün içerisinde teslim edeceğiniz ürünler, sonraki gün <underline>17.30’a kadar  size teslim edilecektir Ürünler aynı gün teslim edilmez.</underline>",

		networkDryDesc1:
			"Etiket Kontrolü: Ürünlerinizin bakım etiketini inceleyin. Kuru temizleme için uygun olup olmadığını kontrol edin ve temizleme talimatlarını dikkate alın. Kuru temizlemeye uygun olmayan ürünleri teslim etmeniz durumunda, üründe oluşabilecek olası sıkıntılar tüketicinin sorumluluğundadır.",
		networkDryDesc2:
			"Ziynet Bildirimi: Ürünlerinizin ceplerinde veya gizli bölmelerinde değerli eşyalarınızı (saat, kolye, nakit para, vb) bırakmayın.",
		networkDryDesc3:
			"Teslim Süresi: Ürünlerinizi teslim alındıktan sonra, ertesi gün 17.30’a kadar size teslim edilecektir. Acil ihtiyaçlarınız varsa bunu önceden belirtin.  (Sipariş notları bölümünde belirtiniz.)",
		networkDryDesc4: "Teslimat Koşulları: Ürünlerinizi kişisel askı ve gamboç ile teslim etmeyin.",
		networkDryDesc5:
			"Leke Tespiti: Ürünlerinizde varsa leke tespit edin ve kuru temizlemeciye bu lekeler hakkında bilgi verin. Lekenin ne olduğunu bilmek temizleme işleminin daha etkili olmasına yardımcı olabilir. (Sipariş notları bölümünde belirtiniz.)",
		networkDryDesc6:
			"Özel Talimatlar: Ürünlerinizin belirli bir bölgesinin hassasiyeti varsa veya belirli bir işlemin uygulanmasını istemiyorsanız, bunu açıkça belirtin. (Sipariş notları bölümünde belirtiniz.)",
		networkDryDesc7:
			"Yıpranma ve Hasar Durumu: Ürünlerinizi teslim etmeden önce herhangi bir yıpranma, sökük veya hasar olup olmadığını kontrol edin. Kuru temizlemeciye bu durumu bildirerek ek önlem almalarını sağlayabilirsiniz. (Sipariş notları bölümünde belirtiniz.)",
		networkDryDesc8: "Aksesuarları Çıkarın: Ürünlerinizde çıkarılabilir aksesuarlar varsa (kemer, broş, vb.), bunları çıkararak ürününüzü teslim edin.",
		networkDryDesc9:
			"Renk Solması: Ürünlerinizin renginin solmaya meyilli olup olmadığını kontrol edin ve eğer varsa kuru temizlemeciye bilgi verin. (Sipariş notları bölümünde belirtiniz.)",
		networkDryCheckedLabel: "Yukarıda belirtilen talimatları okudum, anladım ve onaylıyorum..",
		tourTitle: "Ücretsiz",
		tourDesc: "Her 60€ SİPARİŞİNİZE BOĞAZ TURU HEDİYESİ!",
		tourButton: "Detaylar",
		detailsPage: {
			title:
				"Bu kampanya ile beraber minimum sipariş tutarına eriştiğiniz anda tekne ile bir boğaz turu kazanırsınız. Boğaz Turu hediyesi için siparişinizi şimdi verin. Kampanya detayları aşağıda belirtilmiştir.",
			desc1: "Her 60€’luk sipariş için sadece 1 kişilik hediye bilet verilmektedir.",
			desc2: "Hediye kazanmak için sepet tutarınızın en az 60€ olması gerekmektedir.",
			desc3: "Boğaz Turu, alkolsüz fix menü ve Türk kültürünü yansıtan farklı dans gösterileri içermektedir. ",
			desc4: "Menüde ana yemek, ara sıcak, tatlı ve alkolsüz içecekler dahildir.",
			desc5: "Hediye Boğaz Turu bileti, en erken, sipariş verildikten sonraki gün için kullanılabilir. ",
			desc6: "Rezervasyon onayı ve tur detayları e-posta ile iletilecektir.",
			desc7: "Hediye Boğaz Turu biletini almak için misafir adı-soyadı, kaldığı otel, oda numarası ve geçerli iletişim bilgileri gerekmektedir. ",
			desc8: "İkinci bileti kazanmak için tekrar 60€’luk sipariş vermeniz gerekir.",
		},
		tourPercentageLabel: "Ücretsiz BOĞAZ TURU için sepetinizi {{amount}} 'ya tamamlayın.",
		completedTourBarText: "Tur detayları e-postanıza gönderilecektir.",
		completedSpecialOfferBarText: "{{remain}} kaldı, {{name}} kazanmak için {{price}}!",
		specialOfferPercentageLabel: "{{name}} kazandınız, fiyatı {{price}}!",
		cartItem: "Sepet öğesi",
		cartItems: "Sepet öğeleri",
		offers: "teklifler",
		offer: "teklif",
		orderNumberDoneAuthor: "Sipariş numarası: {{author}}",
	},
};

export const tr = baseStrings;
