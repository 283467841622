import { convertorArrayOption } from "./../utils/convertor";
import france from "../assets/languages/france.png";
import iran from "../assets/languages/iran.png";
import russia from "../assets/languages/russia.png";
import arabic from "../assets/languages/arabic.png";
import spain from "../assets/languages/spain.png";
import turkey from "../assets/languages/turkey.png";
import us from "../assets/languages/us.png";
import germany from "../assets/languages/germany.png";

export const UUID_KY = "ouid";
export const UTM_SOURCE = "utm_source";
export const IS_MAGNET = "isMagnet";
export const IS_CATALOG = "isCatalog";

export const serviceFeeAmount = 12.5;
// ----------------------------------------
export const ERRORS = {
	isRequire: (author) => ({ text: "errors.is-require", params: { author } }),
	isNotValid: (author) => ({ text: "errors.is-not-valid", params: { author } }),
	pleaseEnterYour: (author) => ({ text: "errors.please-enter-your", params: { author } }),
	pleaseEnter: (author) => ({ text: "errors.please-enter", params: { author } }),
	mustBe: (author, kind) => ({ text: "errors.must-be", params: { author, kind } }),
	pleaseSelectYour: (author) => ({ text: "errors.please-select-your", params: { author } }),
	min: (author) => ({ text: "errors.min", params: { author } }),
	max: (author) => ({ text: "errors.max", params: { author } }),
};
// ----------------------------------------

export const LANGUAGE_DATA = [
	{
		value: "en",
		label: "English",
		image: us,
	},
	{
		value: "tr",
		label: "Turkish",
		image: turkey,
	},
	{
		value: "ru",
		label: "Russian",
		image: russia,
	},
	{
		value: "ar",
		label: "Arabic",
		image: arabic,
	},
	{
		value: "de",
		label: "German",
		image: germany,
	},
	{
		value: "fr",
		label: "French",
		image: france,
	},
	{
		value: "es",
		label: "Spanish",
		image: spain,
	},
	{
		value: "fa",
		label: "Persian",
		image: iran,
	},
];
export const LANGUAGE_DATA_OBJECT = {};

convertorArrayOption(LANGUAGE_DATA, LANGUAGE_DATA_OBJECT);

// ----------------------------------------

export const PAYMENT_DATA = [
	{
		value: "online",
		label: "online",
		icon: "fa-solid fa-globe",
		key: "isOnlineActive",
	},
	{
		value: "card",
		label: "card",
		icon: "fa-solid fa-credit-card",
		key: "isCardActive",
	},
	{
		value: "cash",
		label: "cash",
		icon: "fa-solid fa-wallet",
		key: "isCashActive",
	},
];

export const PAYMENT_DATA_OBJECT = {};

convertorArrayOption(PAYMENT_DATA, PAYMENT_DATA_OBJECT);

// ----------------------------------------

export const SYMBOLS_DATA = [
	{
		value: "dollar",
		label: "$",
		isDollar: true,
	},
	{
		value: "try",
		label: "₺",
		isTry: true,
	},
	{
		value: "tl",
		label: "₺",
		isTl: true,
	},
	{
		value: "eur",
		label: "€",
		isEur: true,
	},
];

export const SYMBOLS_DATA_OBJECT = {};

convertorArrayOption(SYMBOLS_DATA, SYMBOLS_DATA_OBJECT);

export const popupNames = {
	specialOrders: "specialOrders",
	tour: "tour",
	specialOffer: "specialOffer",
	christmas: "christmas",
	freeDrink: "freeDrink",
};
