import React from "react";
// import Modal from "react-modal";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

const FreeDrinkPopup = ({ isOpen, onRequestClose }) => {
	const { t } = useTranslation();

	return (
		<Modal
			style={{
				top: "0",
				// alignItems: "center!important",
				// transform: "translate(0%, 20%)"
			}}
			// isOpen={isOpen}
			// onRequestClose={onRequestClose}
			show={isOpen}
			onHide={onRequestClose}
			dialogClassName={styles.modal_dialog}
			aria-labelledby="example-custom-modal-styling-title"
			className={styles["overlay"]}
			contentClassName={styles["content"]}
			// overlayClassName={styles["overlay"]}
			// ariaHideApp={false}
			// size="lg"
		>
			<div className={styles["popup-content"]}>
				<div onClick={onRequestClose} className={styles["close-icon"]}>
					<span aria-hidden="true">&times;</span>
				</div>
				<div className={styles["popup-box"]}>
					<div className={styles["popup-wrap"]}>
						<div onClick={onRequestClose} className={styles["popup-close"]}></div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default FreeDrinkPopup;
