import { getHotel, getHotelMenu, getWeather } from "api/api";
import BottomNav from "components/bottomNav";
import ProductCard from "components/ProductCard";
import SupsisEmbedCode from "components/SupsisEmbedCode";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import FindMe from "reuse/FindMe";
import swal from "sweetalert";
// import { useCarts } from "_context/shoppingCart/cartProvider";
import { useUuid } from "_context/uuid/uuidProvider";
import { pageView } from "../../GTM";

export default function Weather() {
	const [pagination, setPagination] = useState({
		limit: 5,
		page: 1,
		sort: "asc",
	});

	const [stateData, setStateData] = useState({
		result: [],
		total: 0,
	});
	const [datas, setDatas] = useState();
	const { t, i18n } = useTranslation();

	const { data: uuid } = useUuid();
	//   const handleSuccess = (data) => {
	//     console.log("successData===>", data);
	//     let newData = [...stateData?.result];
	//     newData = [...newData, ...data];
	//     console.log("beforeSet", { newData }, data);
	//     setStateData({ result: newData, total });
	//   };
	const handleSuccess = (data) => {
		setDatas(data);
		console.log(datas);
	};

	const { data, isError, error, isLoading, refetch } = useQuery(["recommended", pagination], () => getWeather(uuid, pagination), {
		cacheTime: 0,
		onSuccess: handleSuccess,
	});
	const total = data?.total;
	const maxPage = Math.ceil(total / pagination?.limit);

	//   const handleRemoveCart = (param) => {
	//     removeItem(param);
	//     swal(t("successful"), t("removeFromBasket"), "success");
	//   };

	//   const handleAddCart = (param) => {
	//     addCart(param);
	//     console.log({ param });
	//     swal(t("successful"), t("productAddToCart"), "success");
	//   };

	const handleNextPage = (props) => {
		const nextPage = pagination.page + 1;
		if (pagination?.page < maxPage) setPagination((p) => ({ ...p, page: nextPage }));
	};

	console.log("stateData", stateData);

	useEffect(() => {
		pageView(window?.location?.pathname);
		console.log("pathName", window.location.pathname);
	}, []);

	return (
		<section className="category category-p  mb-3 ">
			{/* <SupsisEmbedCode /> */}
			<div className="container-fluid">
				<div className="category-title  px-2">
					<i class="fa-solid fa-cloud-sun"></i> {datas?.name}
				</div>
				<div className="category-title  px-2">
					<i class="fa-solid fa-temperature-half"></i> {datas?.main.temp}
				</div>
				<div className="category-title  px-2">
					<i class="fa-solid fa-wind"></i> {datas?.wind.speed}
				</div>
			</div>
			{/* <div className={"loader"}>
        {isLoading ? (
          <div className="text-center">
            {" "}
            <Spinner animation="border" />
          </div>
        ) : stateData?.length ? (
          <FindMe callback={handleNextPage} margin={"150px"} />
        ) : (
          // !isLoading ? <NotFound /> :
          ""
        )}
      </div> */}
			<BottomNav />
		</section>
	);
}
